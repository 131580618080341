import { useState, useEffect } from 'react';

import './Waves.css'

function Waves() {

   // viewbox comes in format min-x min-y width height

   /* COLORS FROM CSS VARS */
   // primary color #1c1e21
   // secondary color #24282d
   // tertiary color #2e3136
   // accent color #1abc9c

   // returns a number from 130 - 190
   function getRandomWaveStd() {
      return Math.floor(Math.random() * (190 - 130 + 1)) + 130
   }

   const [std, setStd] = useState(getRandomWaveStd());
   const [intervalId, setIntervalId] = useState(null);

   // interval is every 60s, or once every 2 waves
   // wave length is set in Waves.css via animation duration
   useEffect(() => {
      // start the interval
      const id = setInterval(() => {
         const newValue = getRandomWaveStd()
         setStd(newValue);
      }, 60000);
      setIntervalId(id);

      // clean up the interval
      return () => clearInterval(intervalId);
   }, []);

   return (
      <>
         <div className="wave-bg">
            <div className="wave">
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 230" preserveAspectRatio="xMidYMax slice">
                  <defs>
                     <filter id="glow">
                        <feGaussianBlur stdDeviation={ std } />
                        <feMerge>
                           <feMergeNode />
                           <feMergeNode in="BackgroundImage" />
                        </feMerge>
                     </filter>
                  </defs>
                  {/* <path d="M0,96L48,112C96,128,192,160,288,181.3C384,203,480,213,576,197.3C672,181,768,139,864,144C960,149,1056,203,1152,213.3C1248,224,1344,192,1392,176L1440,160V320H1392C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320H0V96Z" fill="#1abc9c" /> */}
                  <path d="M0,96C48,112,96,128,192,160C288,192,384,224,480,213.3C576,203,672,149,768,144C864,139,960,181,1056,197.3C1152,213,1248,203,1344,176C1392,160,1440,128,1440,128V0H0V96Z" fill="rgba(26, 188, 156, 0.2)" stroke="rgba(26, 188, 156, 0.1)" strokeWidth="0" filter="url(#glow)" />

               </svg>
            </div>
            <div className="wave">
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 80" preserveAspectRatio="xMidYMax slice">
                  {/* <path d="M0,96L48,112C96,128,192,160,288,181.3C384,203,480,213,576,197.3C672,181,768,139,864,144C960,149,1056,203,1152,213.3C1248,224,1344,192,1392,176L1440,160V320H1392C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320H0V96Z" fill="#1abc9c" /> */}
                  <path d="M1440,128C1392,112,1344,96,1248,64C1152,32,1056,0,960,10.7C864,21,768,75,672,80C576,85,480,43,384,48C288,53,192,107,96,128C48,139,0,149,0,149V240H1440V128Z" fill="rgba(26, 188, 156, 0.2)" stroke="rgba(26, 188, 156, 0.1)" strokeWidth="0" filter="url(#glow)" />
               </svg>
            </div>
         </div>
      </>
   )
}

export default Waves;