import '../App.css';


function FractalSVG(props) {
   return (
      <>
         <svg className='mask' xmlns="http://www.w3.org/2000/svg">
            <defs>
               <filter id="stripes">
                  <feTurbulence baseFrequency={`${props.baseFrequencyX} ${props.baseFrequencyY}`} />
                  <feColorMatrix
                     values=".65 .1 0  0 0
                  .15 .4 0  0 0
                  .07 .1 .1 0 0
                  0   0  0  0 1"
                  />
               </filter>
               <pattern id="pattern" width="1" height="1" patternTransform="rotate(45)">
                  <rect width="140%" height="140%" filter="url(#stripes)" />
               </pattern>
               <filter id="filter">
                  <feTurbulence type="fractalNoise" baseFrequency={`${props.filterFrequencyX} ${props.filterFrequencyY}`} numOctaves={props.numberOfOctaves} />
                  <feDisplacementMap in="SourceGraphic" scale="250" />
               </filter>
            </defs>
            <rect x="-20%" y="-20%" width="140%" height="140%" fill="url(#pattern)" filter="url(#filter)" />
         </svg>
      </>

   );
}

export default FractalSVG;