import { Routes, Route, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

import Home from './Home.js';
import GetStarted from './GetStarted.js';

/* wrapper for setting transition animations on route change */
function RoutingTransitionWrapper(props) {
   const location = useLocation();

   return (
      <motion.div
         key={location.pathname}
         initial={{ opacity: 0, y: 0, scale: 0.99 }}
         animate={{ opacity: 1, y: 0, scale: 1 }}
         exit={{ opacity: 0, y: 0, scale: 0.99 }}
         transition={{ duration: 0.3 }}
      >
         <Routes location={location}>
            <Route path='/Home' element={
               <Home
                  setSliderVisibility={props.setSliderVisibility}
                  startBGUpdate={props.startBGUpdate}
                  shouldUpdate={true} // root should not trigger bg update, but /Home should
               />}
            />
            <Route path='/*' element={
               <Home
                  setSliderVisibility={props.setSliderVisibility}
                  startBGUpdate={props.startBGUpdate}
               />}
            />
            <Route path='/GetStarted' element={
               <GetStarted
                  setSliderVisibility={props.setSliderVisibility}
                  startBGUpdate={props.startBGUpdate}
               />}
            />
         </Routes>
      </motion.div>
   );
}

export default RoutingTransitionWrapper;