import { useEffect, useState } from 'react';
import '../App.css';
import './GetStarted.css';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { faUserGroup } from '@fortawesome/free-solid-svg-icons';

function GetStarted(props) {
   useEffect(() => {
      props.setSliderVisibility(false);
      props.startBGUpdate(true);
   }, [])

   const [lastClicked, setLastClicked] = useState(null);

   const handleClick = (e) => {
      if (lastClicked) {
         lastClicked.classList.remove('active');
      }
      if (lastClicked !== e.currentTarget) {
         e.currentTarget.classList.add('active');
         setLastClicked(e.currentTarget);
      } else {
         setLastClicked(null);
      }
   };

   return (
      <>
         <div className='fullHeight'>

            <div className='whoAreYou'>
               Who Are You Representing?
            </div>

            <div className="getStartedContainer">
               <div id='cardDiv' className='cardContainer'>

                  <div className="getStarted-card emissive-shadow" onClick={handleClick}>
                     <div className='getStarted-cardContent'>
                        Me
                        <FontAwesomeIcon icon={faUser} style={{ color: 'inherit', fontSize: '5rem' }} />
                     </div>
                  </div>

                  <div className="getStarted-card emissive-shadow" onClick={handleClick}>
                     <div className="getStarted-cardContent">
                        My Team
                        <FontAwesomeIcon icon={faUserGroup} style={{ color: 'inherit', fontSize: '5rem' }} />
                     </div>
                  </div>

                  <div className="getStarted-card emissive-shadow" onClick={handleClick}>
                     <div className="getStarted-cardContent">
                        My Organization
                        <FontAwesomeIcon icon={faUsers} style={{ color: 'inherit', fontSize: '5rem' }} />
                     </div>
                  </div>

               </div>

               <div className="getStarted-buttonRow">
                  <Link to="/GetStartedContinued" >Continue</Link>
               </div>
            </div>

         </div>
      </>
   );
}

export default GetStarted;