import './App.css';
import Navbar from "./components/Navbar";
import RoutingTransitionWrapper from './components/RoutingTransitionWrapper';

import { BrowserRouter } from 'react-router-dom';
import { renderToStaticMarkup } from 'react-dom/server';
import React, { useEffect, useState } from 'react';
import FractalSVG from './components/FractalSVG';

import { Slider } from 'primereact/slider';
import { InputText } from 'primereact/inputtext';
import { ToggleButton } from 'primereact/togglebutton';

import { AnimatePresence } from 'framer-motion';

import { createBrowserHistory } from 'history';
// this history allows the browser forward-back arrows to not trigger "page blink"
const history = createBrowserHistory();

function App() {
   const [showSliders, setShowSliders] = useState(true);

   const [baseFrequencyX, setBaseFrequencyX] = useState(0.6431);
   const [baseFrequencyY, setBaseFrequencyY] = useState(0);

   const [filterFrequencyX, setFilterFrequencyX] = useState(0.01);
   const [filterFrequencyY, setFilterFrequencyY] = useState(0.01);

   const [numberOfOctaves, setNumberOfOctaves] = useState(5);

   useEffect(() => {
      const fractalString = renderToStaticMarkup(<FractalSVG baseFrequencyX={baseFrequencyX}
         baseFrequencyY={baseFrequencyY}
         filterFrequencyX={filterFrequencyX}
         filterFrequencyY={filterFrequencyY}
         numberOfOctaves={numberOfOctaves}
      />);
      document.body.style.backgroundImage = `url("data:image/svg+xml,${encodeURIComponent(fractalString)}")`;

      return () => {
         // Clean up the background image when the component unmounts
         document.body.style.backgroundImage = null;
      };
   }, [baseFrequencyX, baseFrequencyY, filterFrequencyX, filterFrequencyY, numberOfOctaves])

   // callback so child components can update slider visibility in App()
   // this needs to be called (downstream) in an empty useEffect to avoid timing issues rendering the component tree
   const setSliderVisibility = (val = false) => {
      setShowSliders(val);
   }

   // vars for fractal transitions
   const [toggleAnimatedBG, setToggleAnimatedBG] = useState(false);
   const [endBGValue, setEndBGValue] = useState(baseFrequencyX - 0.001);
   let startTime;
   let animationFrameId;

   const startIncrement = (timestamp) => {
      startTime = timestamp;
      animate(timestamp);
   };

   const animate = (timestamp) => {
      const elapsed = timestamp - startTime;
      const duration = 150; // Total duration of the animation in milliseconds
      const startValue = baseFrequencyX;
      const endValue = endBGValue; // The final value you want to reach
      const progress = Math.min(elapsed / duration, 1); // Calculate the progress as a value between 0 and 1
      const easedValue = easeInOutQuad(progress, startValue, endValue);

      setBaseFrequencyX(easedValue);

      if (elapsed < duration) {
         animationFrameId = requestAnimationFrame(animate);
      }
   };

   // cleanup for animation frame, helps performance on low end systems
   useEffect(() => {
      return () => {
         cancelAnimationFrame(animationFrameId);
      };
   }, []);

   // chat gpt generated
   const smoothStep = (t, start, end) => {
      const delta = end - start;
      t = t * t * (3 - 2 * t);
      return delta * t + start;
   };

   const easeInOutQuad = (t, start, end) => {
      t /= 0.5;
      const delta = end - start;
      if (t < 1) return delta / 2 * t * t + start;
      t--;
      return -delta / 2 * (t * (t - 2) - 1) + start;
   };

   const easeInOutCubic = (t, start, end) => {
      t /= 0.5;
      const delta = end - start;
      if (t < 1) return delta / 2 * t * t * t + start;
      t -= 2;
      return delta / 2 * (t * t * t + 2) + start;
   };

   const startBGUpdate = (incomingUpdateDir) => {
      if (incomingUpdateDir) {
         setEndBGValue(baseFrequencyX + 0.001);
      } else {
         setEndBGValue(baseFrequencyX - 0.001);
      }

      // LG TODO: decide whether to keep this functionality, probably too much
      // keeping the code incase we want to use it eventually
      if (toggleAnimatedBG)
         requestAnimationFrame(startIncrement);
   }

   return (
      <BrowserRouter history={history}>
         <>
            <Navbar setSliderVisibility={setSliderVisibility} />

            {/* FRACTAL SLIDERS */}
            {showSliders &&
               <div className='slider-wrapper'>
                  <div className='baseFrequencyXContainer'>
                     <div className='flex flex-column justfiy-content-center'>
                        <label className='' htmlFor='baseFrequencyX'>Base Frequency</label>
                        <InputText className='w-full sliderInput' disabled value={baseFrequencyX.toFixed(2)} onChange={(e) => setBaseFrequencyX(e.target.value)} />
                        <Slider className='w-full' step={0.001} max={0.99} value={baseFrequencyX} onChange={(e) => setBaseFrequencyX(e.value)} />
                     </div>
                  </div>

                  <div className='filterFrequencyXContainer'>
                     <div className='flex flex-column justfiy-content-center'>
                        <label className='' htmlFor='filterFrequencyX'>Filter Frequency X</label>
                        <InputText className='w-full sliderInput' disabled value={filterFrequencyX.toFixed(2)} onChange={(e) => setFilterFrequencyX(e.target.value)} />
                        <Slider className='w-full' step={0.001} max={0.1} value={filterFrequencyX} onChange={(e) => setFilterFrequencyX(e.value)} />
                     </div>
                  </div>

                  <div className='filterFrequencyYContainer'>
                     <div className='flex flex-column justfiy-content-center'>
                        <label className='' htmlFor='filterFrequencyY'>Filter Frequency Y</label>
                        <InputText className='w-full sliderInput' disabled value={filterFrequencyY.toFixed(2)} onChange={(e) => setFilterFrequencyY(e.target.value)} />
                        <Slider className='w-full' step={0.001} max={0.1} value={filterFrequencyY} onChange={(e) => setFilterFrequencyY(e.value)} />
                     </div>
                  </div>

                  <div className='numberOfOctavesContainer'>
                     <div className='flex flex-column justfiy-content-center'>
                        <label className='' htmlFor='numberOfOctaves'>Number of Octaves</label>
                        <InputText className='w-full sliderInput' disabled value={numberOfOctaves.toFixed(0)} onChange={(e) => setNumberOfOctaves(e.target.value)} />
                        <Slider className='w-full' step={1} max={10} value={numberOfOctaves} onChange={(e) => setNumberOfOctaves(e.value)} />
                     </div>
                  </div>

                  <div className='animatedTransitionToggleContainer'>
                     <div className='flex flex-column justfiy-content-center'>
                        <label className='' htmlFor='bgToggle'>Toggle Animated Background</label>
                        <ToggleButton
                           id='bgToggle'
                           className='w-full mt-3'
                           checked={toggleAnimatedBG}
                           onChange={(e) => setToggleAnimatedBG(e.value)}
                           onLabel='On'
                           offLabel='Off'
                        />
                     </div>
                  </div>

               </div>
            }
            {/* END SLIDERS */}

            <AnimatePresence mode="wait">
               {/* Using a component wrapper allows us to use "useLocation" with our routing transitions */}
               <RoutingTransitionWrapper
                  setSliderVisibility={setSliderVisibility}
                  startBGUpdate={startBGUpdate}
               />
            </AnimatePresence>

         </>
      </BrowserRouter>
   );
}

export default App;
