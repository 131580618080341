import './Home.css';
import '../App.css';

import { Link } from 'react-router-dom';
import { useEffect } from 'react';

function Home(props) {

   useEffect(() => {
      props.setSliderVisibility(true);

      if (props.shouldUpdate) {
         props.startBGUpdate(false);
      }
   }, [])


   return (
      <>
         <div className="homeContainer">

            <div className="missonStatement-container">
               <div className="missionStatement-text emissive-shadow">
                  {/* Delivering cutting-edge prebuilt computers, installed with the latest pre-trained open source LLM models,
               decentralizing AI. Enhancing team productivity without sacrificing data privacy and IP security. */}
                  Experience unparalleled performance with our state-of-the-art prebuilt computers, featuring the latest open-source Large Language Models (LLMs).
                  Unlock the power of decentralized AI to boost your team's productivity, all while ensuring the utmost data privacy and IP security.
                  Invest in the future of computing and transform your business today!
               </div>
            </div>
            <div className="missionStatement-buttonRow">
               <Link to="/GetStarted" style={{ marginRight: '25px' }}>Get Started</Link>
               <Link to="#">About Us</Link>
            </div>

         </div>
      </>
   );
}

export default Home;