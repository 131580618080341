import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import './Navbar.css';
import './Waves.css';
import '../App.css';

import Waves from './Waves';

function Navbar() {

   useEffect(() => {
      const typingAnimation = document.querySelector('.title-typing-text')
      const cursorAnimation = document.querySelector('#title-cursor')
      typingAnimation.addEventListener('animationend', () => {
         cursorAnimation.classList.add('animate')
      })

      return () => { }
   }, [])

   return (
      <nav className='nav-bg'>
         <div id='title' className='siteTitle'>
            <div className='titleContent'>
               <span id='title-text' className='title-typing-text'>Emergence Machines</span>
               <span id="title-cursor" className='terminal-cursor'></span>
            </div>
         </div>
         <ul>
            {/* <li><a href="/Home">Home</a></li>
            <li><a href="/about">About</a></li>
            <li><a href="/contact">Contact</a></li> */}
            <li><Link to="/Home">Home</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/contact">Contact</Link></li>
         </ul>
         <Waves />
      </nav>
   );
}

export default Navbar;

